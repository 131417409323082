/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
// 表单配置信息
export const collapse = [
    {
        title: '变更概况',
        ref: 'gaikuangForm',
        formName: 'gaikuangInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '客户名称',
                prop: 'customerName',
                value: 'customerName',
                type: 'custom',
            },
            {
                label: '原战略合作类型',
                prop: 'teamType',
                value: 'teamType',
                type: 'custom'
            },
            {
                label: '变更操作',
                prop: 'changeType',
                value: 'changeType',
                type: 'custom'
            },
            {
                label: '变更原因',
                prop: 'changeReason',
                value: 'changeReason',
                type: 'input',
                inputType: 'textarea',
                rows: 3,
                span: 24
            },
        ]
    },
    // {
    //     title: '系统信息',
    //     ref: 'systemForm',
    //     formName: 'systemInfo',
    //     col: 4,
    //     labelWidth: '170px',
    //     visible: true,
    //     formList: [
    //         {
    //             label: '审批状态（MIP）',
    //             prop: 'mipStatus',
    //             value: 'mipStatus',
    //             type: 'custom',
    //             optionsKey: 'SYS_SU_MIP_AUDIT_STATUS'
    //         },
    //         {
    //             label: '申请人',
    //             prop: 'applicantName',
    //             value: 'applicant',
    //             type: 'custom'
    //         },
    //         {
    //             label: '申请日期',
    //             prop: 'applicationDate',
    //             value: 'applicationDate',
    //             type: 'custom'
    //         },
    //         {
    //             label: '流程类型（MIP）',
    //             prop: 'mipType',
    //             value: 'mipType',
    //             optionsKey: 'MIP_TYPE',
    //             type: 'custom'
    //         },
    //         {
    //             label: '当前流程节点',
    //             prop: 'mipNode',
    //             value: 'mipNode',
    //             type: 'custom'
    //         },
    //         {
    //             label: '当前处理人',
    //             prop: 'handlePerson',
    //             value: 'handlePerson',
    //             type: 'custom'
    //         },
    //         {
    //             label: '审批通过日期',
    //             prop: 'approvedDate',
    //             value: 'approvedDate',
    //             type: 'custom'
    //         },
    //         {
    //             label: 'MIP流程ID',
    //             prop: 'mipFlowId',
    //             value: 'mipFlowId',
    //             type: 'custom'
    //         }
    //     ]
    // }
];
// 评分明细配置
export const scoreConfig = [
    {
        title: '资质',
        formName: 'qualifications',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '成立年限',
                prop: 'establish',
                value: 'establish',
                type: 'custom',
            },
            {
                label: '操盘手',
                prop: 'trader',
                value: 'trader',
                type: 'custom',
            },
            {
                label: '推广团队',
                prop: 'marketingTeam',
                value: 'marketingTeam',
                type: 'custom',
            },
            {
                label: '办公环境',
                prop: 'officeEnvironment',
                value: 'officeEnvironment',
                type: 'custom',
            },
            {
                label: '近三年发展趋势',
                prop: 'pastThreeTrends',
                value: 'pastThreeTrends',
                type: 'custom',
            }
        ]
    },
    {
        title: '物流能力',
        formName: 'logisticsCapability',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '仓库空仓/扩容面积',
                prop: 'expansionArea',
                value: 'expansionArea',
                type: 'custom',
            },
            {
                label: '仓租成本竞争力',
                prop: 'whCompete',
                value: 'whCompete',
                type: 'custom',
            },
            {
                label: '车辆数',
                prop: 'vehicles',
                value: 'vehicles',
                type: 'custom',
            },
            {
                label: '配送成本竞争力',
                prop: 'deliveryCompete',
                value: 'deliveryCompete',
                type: 'custom',
            }
        ]
    },
    {
        title: '商流能力',
        formName: 'businessFlowCapability',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '商流规模',
                prop: 'businessScale',
                value: 'businessScale',
                type: 'custom',
            },
            {
                label: '运作品类',
                prop: 'operateCategory',
                value: 'operateCategory',
                type: 'custom',
            },
            {
                label: '运营品牌',
                prop: 'proxyBrand',
                value: 'proxyBrand',
                type: 'custom',
            },
            {
                label: '覆盖终端',
                prop: 'coverStores',
                value: 'coverStores',
                type: 'custom',
            },
            {
                label: '物流交付',
                prop: 'logisticsDelivery',
                value: 'logisticsDelivery',
                type: 'custom',
            },
            {
                label: '商品周转',
                prop: 'stockTurnover',
                value: 'stockTurnover',
                type: 'custom',
            }
        ]
    },
    {
        title: '转型',
        formName: 'transformation',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '向上（A类）',
                prop: 'up1',
                value: 'up1',
                type: 'custom',
            },
            {
                label: '向上（B/C类）',
                prop: 'up2',
                value: 'up2',
                type: 'custom',
            },
            {
                label: '向下',
                prop: 'down',
                value: 'down',
                type: 'custom',
            },
            {
                label: '向前',
                prop: 'forward',
                value: 'forward',
                type: 'custom',
            },
            {
                label: '向后',
                prop: 'backward',
                value: 'backward',
                type: 'custom',
            }
        ]
    },
    {
        title: '潜力',
        formName: 'potential',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '合仓意愿',
                prop: 'mergeWill',
                value: 'mergeWill',
                type: 'custom',
            },
            {
                label: '转型意愿',
                prop: 'transformWill',
                value: 'transformWill',
                type: 'custom',
            },
            {
                label: '行业地位',
                prop: 'industryStatus',
                value: 'industryStatus',
                type: 'custom',
            }
        ]
    }
];
