<!-- eslint-disable max-lines -->
<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="top-box">
            <!-- 标题区域 -->
            <div class="header-box">
                <h4>战合客户终止申请</h4>
                <div class="header-info-crm">
                    <!-- <div class="tips">合作模式说明：{{ detailDataLocal.accessWarn }}</div> -->
                    <div class="tips">
                        <div>战略合作类型说明</div>
                        <div>{{ detailDataLocal.accessWarn }}</div>
                    </div>
                    <div class="apply-info">
                        <div>申请人：{{ detailDataLocal.applicantName }}</div>
                        <div>部门：{{ detailDataLocal.applicantDepartName }}</div>
                    </div>
                </div>
            </div>
            <!-- 按钮区域 -->
            <div class="detail-toolBar">
                <el-tooltip class="item-flow-right" effect="dark" content="刷新" placement="bottom">
                    <lots-button
                        size="mini"
                        icon="el-icon-refresh"
                        class="refresh"
                        @click="refleshPage()"
                        :loading="detailLoading">
                    </lots-button>
                </el-tooltip>
            </div>
        </div>
        <div class="strategic-cooperation-introduce-apply"
            v-loading="detailLoading">
            <!-- 表单区域 -->
            <collapse-infos
                ref="upholdHealder"
                :detailData="detailDataLocal"
                :collapse="COLLAPSE"
                :activeCollapses="activeCollapses"
                @change="handleInfosChange">
                <!-- 折叠筐标题插槽 -->
                <template v-slot:collapse-title-append="{collapseItem}">
                    <span >{{ collapseItem.title }}</span>
                </template>
            </collapse-infos>
            <!-- iflow区域 -->
            <el-collapse
                v-if="detailDataLocal.mipFlowId"
                class="contract-collapse detail-more-collapse"
                value="mipInfo">
                <el-collapse-item title="准入审批" name="mipInfo">
                    <div class="header-operation">
                        <lots-button
                            size="mini"
                            type="primary"
                            @click="submitMip"
                            v-if="readonly"
                            :loading="btnloading">
                            mip提交
                        </lots-button>
                    </div>
                    <iframe
                        class="ifr"
                        :src="mipUrl"
                        v-if="!btnloading" />
                </el-collapse-item>
            </el-collapse>
        </div>
    </paper>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api';
import paper from '@/components/core/Paper.vue';
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import { queryTeamTerminationChange } from '@mdm/api/contractManage/strategicCooperationTermination.js';
import { approveProcess } from '@/modules/mdm/api/contractManage/detailMainApi.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import { Message } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
import { collapse } from './config.js';
import _ from 'lodash';
import configurl from '@/config/user.env';
import { getProcessInfoApi } from '@mdm/api/contractManage/mipIframeApi.js';
const { MAGIC_NUMBER } = utils;
export default {
    name: 'strategicCooperationIntroduceUpdate',
    components: { paper, lotsButton, CollapseInfos },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(collapse);
        const detailLoading = ref(false);
        const btnloading = ref(false);
        const disabledFlag = ref(false);
        const code = ref('');
        const readonly = ref(true);
        const detailDataLocal = ref({
            accessWarn: '战略合作模式分为：A类（系统+底盘+业务开发+品牌新增长）、B类（系统+业务开发+品牌新增长）、C类（商流系统+业务开发+品牌新增长）合作模式。',
            mipFlowId: ''
        });
        const tableData = computed(() => {
            function getTableData(prop) {
                return detailDataLocal.value[prop];
            }
            return getTableData;
        });
        // eslint-disable-next-line max-len
        const activeCollapses = ref(['gaikuangInfo', 'systemInfo']);
        const mipUrl = ref('');
        const lodash = computed(() => { return _; });
        const COLLAPSE = computed(() => { // 配置项动态计算
            config.forEach((v, i) => {
                v.formList.forEach((v2, i2) => {
                    if (readonly.value) { // 只读状态
                        if (v2.inputType === 'textarea') {
                            v2.disabled = true;
                        } else {
                            v2.type = 'custom';
                        }
                    } else { // 编辑状态
                        v2.type = (v2.sourceType || v2.type);
                        // 除了固定只读的字段，其他的取消只读状态
                        // eslint-disable-next-line max-len
                        const readonlyKeys = ['overallRiskInfo', 'coverRegion'];
                        !readonlyKeys.includes(v2.prop) && (v2.disabled = false);
                    }
                });
            });
            return config;
        });

        const newMip = ref(false);
        const refleshPage = (customerChangeId = ctx.$route.query.customerChangeId) => {
            _initData(customerChangeId);
        };
        // 处理详情返回数据格式
        const formatData = (data) => {
            const newData = _.cloneDeep(data);
            !newData.relatedFiles && (newData.relatedFiles = []);
            return newData;
        };
        const { userName, userCode } = store.getters.user;
        // eslint-disable-next-line complexity
        const _initData = async (id) => {
            const { customerChangeId } = ctx.$route.query;
            let res = null;
            detailLoading.value = true;
            if (id || customerChangeId) {
                res = await queryTeamTerminationChange({ customerChangeId: id || customerChangeId });
                readonly.value = true;
            }
            detailLoading.value = false;
            if (customerChangeId) {
                // 获取最新流程信息
                detailLoading.value = true;
                const iflowRes = await contractWorkflowList({ contractCode: customerChangeId });
                detailLoading.value = false;
                if (+iflowRes.code === 0 && iflowRes.data && iflowRes.data.list && iflowRes.data.list.length) {
                    const { mipId, mipType, mipStatus } = iflowRes.data.list[0];
                    const publicPrarms = { fdId: mipId, loginName: userCode };
                    // 获取流程当前节点、当前操作人信息
                    detailLoading.value = true;
                    const currentNodeRes = await getProcessInfoApi(publicPrarms);
                    detailLoading.value = false;
                    let mipNode = '';
                    let handlePerson = '';
                    // eslint-disable-next-line max-depth
                    if (currentNodeRes && +currentNodeRes.code === 0 && currentNodeRes.data
                        && currentNodeRes.data.currNodes && currentNodeRes.data.currNodes.length) {
                        currentNodeRes.data.currNodes.forEach((item, index) => {
                            mipNode += item.nodeName + `${index === currentNodeRes.data.currNodes.length - 1 ? '' : '、'}`;
                            if (item.handlers && item.handlers.length) {
                                item.handlers.forEach((sub, index) => {
                                    handlePerson += sub.name + `${index === item.handlers.length - 1 ? '' : '、'}`;
                                });
                            }
                        });
                    }
                    const object = {
                        ...res.data, // 表单原始数据
                        mipFlowId: mipId, // 流程id
                        mipType, // 流程类型
                        mipStatus, // 流程状态
                        mipNode, // 当前节点
                        handlePerson // 当前节点操作人
                    };
                    handleInfosChange({ form: formatData(object) });
                } else {
                    handleInfosChange({ form: formatData(res.data) });
                }
            } else {
                handleInfosChange({ form: formatData(res.data) });
            }
        };
        const isHandleEdit = ref(false);
        const handleInfosChange = (formObjData = {}) => { // 表单组件编辑回调，更新本地数据
            if (formObjData.form && formObjData.form.businessType && !formObjData.form.businessType.includes('快递')) {
                // 业务类别不包含快递时，清空聚焦业态和是否控仓；
                formObjData.form.focusBusinessFormats = '';
                formObjData.form.isWarehouse = '';
            }
            const { itemSetting } = formObjData;
            // 监控表单是否编辑过
            isHandleEdit.value = Boolean(itemSetting);
            if (!itemSetting) { // 页面初始化、刷新时走：
                let rating, score;
                if (formObjData.form.ratingScoreList?.length) { // 提取评分数据到外层
                    rating = formObjData.form.ratingScoreList[0].rating;
                    score = formObjData.form.ratingScoreList[0].score;
                }
                detailDataLocal.value = Object.assign({}, detailDataLocal.value, formObjData.form, { rating, score });
                if (detailDataLocal.value.mipFlowId) {
                    handleCreate(); // 渲染iflow流程
                }
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.clearValidate(); // 重置校验报错
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.refleshPage(detailDataLocal.value); // 刷新表单子组件
            } else { // collapse组件编辑回调时走：
                const { relatedFiles } = detailDataLocal.value;
                // 除了插槽参数以外，其他参数取最新编辑的参数更新(因：插槽的编辑不会触发collapse组件change事件回调，因此change事件回调的插槽的参数是旧数据不能取)
                detailDataLocal.value = Object.assign(
                    {},
                    detailDataLocal.value,
                    formObjData.form,
                    { relatedFiles } // 保证插槽数据用最新的
                );
            }
        };
        const handleCreate = () => {
            mipUrl.value = '';
            mipUrl.value = `${configurl.iflowUrl}?fdId=${detailDataLocal.value.mipFlowId}&fdTemplateKey=CRM_team_account_to_admit&userCode=${userCode}&userName=${userName}`;
            createMetaNode();
            window.addEventListener('message', messageFunc, false);
            newMip.value && setTimeout(() => {
                const element = document.getElementsByClassName('mip-iframe')[0];
                element.scrollTo(0, MAGIC_NUMBER.TWO_THOUSAND); // 页面滚动到MIP审批位置
                newMip.value = false;
            }, MAGIC_NUMBER.FOUR_HUNDRED);
        };
        const createMetaNode = () => {
            const mipmeta = document.getElementById('mipmeta');
            if (mipmeta) {
                return false;
            }
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = 'upgrade-insecure-requests';
            meta.id = 'mipmeta';
            document.getElementsByTagName('head')[0].appendChild(meta);
        };
        const messageFunc = (event) => {
            if (event.data && event.data.data && event.data.success) {
                const { customerChangeId, applicationId } = detailDataLocal.value;
                const { origin, pathname } = window.location;
                const params = event.data.data || {};
                const { tenantCode } = store.getters.currentTenant;
                params.tenantCode = tenantCode;
                // params.currentApplicationCode = this.applicationCode;
                params.currentApplicationCode = 'APP201904230047';
                if (params.formParam) {
                    // params.formParam.formInstanceId = this.$route.query.id;
                    params.formParam.formInstanceId = applicationId;
                    const { processParam } = params;
                    let isToOtherPersons = '';
                    if (processParam.operationType === 'circulate' && processParam.toOtherPersons) {
                        isToOtherPersons = processParam.toOtherPersons; // 被传阅的人
                    }
                    // eslint-disable-next-line max-len
                    params.formParam.fdUrl = `${origin}${pathname}#/strategicCooperationTermination?customerChangeId=${customerChangeId}${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                }
                btnloading.value = true;
                approveProcess(params).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('提交成功');
                        ctx.$router.go(0);
                    } else {
                        Message.error(res.msg);
                    }
                }).finally(() => {
                    btnloading.value = false;
                });
            }
        };
        // eslint-disable-next-line complexity
        const submitMip = async () => {
            const { applicationId, mipFlowId } = detailDataLocal.value;
            if (applicationId) {
                const iflowRes = await contractWorkflowList({ contractCode: applicationId });
                if (+iflowRes.code === 0) {
                    if (iflowRes.data && iflowRes.data.list && iflowRes.data.list.length && iflowRes.data.list[0].mipId
                        && iflowRes.data.list[0].mipId === mipFlowId) {
                        // 通过的，不做卡控
                    } else {
                        Message.warning('页面审批流程信息已过期，请刷新页面再试');
                        return false;
                    }
                } else {
                    return false;
                }
            }
            const content = window;
            content.frames && content.frames.length && content.frames[0]
                && content.frames[0].postMessage('mbpm_getApprovalData', mipUrl.value);
            content.frames && content.frames.length && content.frames[1]
                && content.frames[1].postMessage('mbpm_getApprovalData', mipUrl.value); // 解决接入了智能客服后，content.frames[0]被占用了的问题
        };
        watch( // 注意：加了immediate: true的话，watch监听要在放在最后，否则报错涉及的调用方法找不到！
            () => ctx.$route,
            (newVal) => {
                if (newVal.name === 'strategicCooperationTermination') {
                    const { customerChangeId } = detailDataLocal.value;
                    !customerChangeId && newVal.query.customerChangeId && refleshPage();
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        return {
            config,
            COLLAPSE,
            detailLoading,
            btnloading,
            disabledFlag,
            code,
            detailDataLocal,
            mipUrl,
            refleshPage,
            handleInfosChange,
            submitMip,
            readonly,
            activeCollapses,
            lodash,
            tableData
        };
    }
};
</script>
<style lang="less">
@scrollbarThumbColor: rgba(0,0,0, .2);
@scrollbarThumbColorHover: rgba(0,0,0, .4);

.mip-iframe {
    // overflow: auto;
    display: flex;
    flex-direction: column;
    .top-box {
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
            }
            .header-info-crm {
                width: 100%;
                display: flex;
                font-size: 14px;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
                .tips {
                    flex: 1;
                    // color: red;
                    color: #606266;
                    line-height: 20px;
                }
                .apply-info {
                    width: 200px;
                    line-height: 20px;
                    color: #606266;
                    margin-left: 10px;
                }
            }
        }
        .detail-toolBar {
            .item-flow-right {
                float: right;
                &.refresh {
                    margin-left: 10px;
                }
            }
        }
    }
    .strategic-cooperation-introduce-apply {
        overflow: auto;
        .upload-demo {
            display: inline-block;
        }
        .down-mould-btn {
            margin-left: 5px;
        }
        .upload-tips {
            display: inline-block;
            margin-left: 10px;
            line-height: 28px;
            font-size: 12px;
            color: #606266;
        }
        .file-infos {
            width: 100%;
            padding: 0 10px 0 0;
            .file-list {
                width: 100%;
                padding: 2px 0 2px 4px;
                display: flex;
                // justify-content: space-between;
                .file-name {
                    // flex: 1;
                    &.upload {
                        width: 500px;
                    }
                    padding-right: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
                .view-btn, .delete-btn {
                    width: 200px;
                    display: flex;
                    a {
                        text-decoration: none;
                        color: #4285F5;
                    }
                    .el-button {
                        margin-left: 10px;
                    }
                }
            }
        }
        .el-collapse-item__content {
            padding-bottom: 5px;
        }
        .el-collapse-item__arrow {
            margin: 0 8px 0 5px;
        }
        .el-button.is-disabled.is-plain,
        .el-button.is-disabled.is-plain:focus,
        .el-button.is-disabled.is-plain:hover {
            border-color: #FFF;
        }
        .width-small {
            width: 340px;
        }
        .width-middle {
            width: 500px;
        }
        .width-large {
            width: 1200px;
        }
        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 2px;
        }
        .el-col.el-col-23.form-item {
            .el-form-item.el-form-item--mini {
                margin-bottom: 18px;
            }
        }
        .el-collapse-item__header {
            height: 32px;
        }
        .detail-form-collapse {
            padding-right: 10px;
        }
        .score-config-item-box {
            .item-tittle {
                font-size: 14px;
                font-weight: 600;
                color: #606266;
                margin-left: 28px;
            }
        }
        .rating-score-list-box {
            .el-collapse {
                border-bottom: none;
                .el-collapse-item__header {
                    border-bottom: none;
                }
                .el-collapse-item__wrap {
                    border-bottom: none;
                }
            }
        }
    }
    .ifr {
        width: 100%;
        height: 100vh;
        border: none;
    }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .header-operation {
        margin-top: 10px;
    }
}
.no-auth {
    font-size: 16px;
}
.my-tooltip-box {
    width: 800px;
}
</style>
