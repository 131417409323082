import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 查询战合终止信息-yjl
export const queryTeamTerminationChange = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/teamTermination/query',
        method: 'post',
        data
    });
};
