import request from '@/utils/http';
// import utils from '@/utils/common';
import configurl from '@/config/user.env';
const apiCrmHost = 'api-lcrm';
// const apiDcHost = 'api-dc';

// 附件明细列表查询
export const contractFilesList = (params) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/page',
        method: 'get',
        params
    });
};
// 附件明细列表删除
export const contractFilesDelete = (data) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/remove',
        method: 'post',
        data
    });
};
// 附件明细列表下载
export const contractFilesDownload = (params) => {
    let getUrl = configurl.apiUrl + apiCrmHost + '/contractEnclosureInfo/download?';
    Object.keys(params).forEach(key => {
        getUrl += key + '=' + params[key] + '&';
    });
    window.open(getUrl, '_self');
    // return request({
    //     url: configurl.apiUrl + apiCrmHost + '/contractEnclosureInfo/download',
    //     method: 'get',
    //     responseType: 'blob',
    //     params
    // }).then(res => {
    //     utils.downloadStream(res, params.contractName);
    // });
};
// 附件明细列表预览
export const contractFilesView = (data) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/view',
        method: 'post',
        data
    });
};
// 附件明细新增（预填充）
export const contractFilesPrefillAddApi = (data) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/prefill/add',
        method: 'post',
        data
    });
};
// 查询项目（通过projectCode获取项目类型projectType）
export const getCdTsCustomerProjectsApi = (params) => {
    return request({
        url: 'api-lcrm/cdTsCustomerProjects',
        method: 'get',
        params
    });
};

// 客户明细查询
export const contractCustomerList = (params) => {
    return request({
        url: apiCrmHost + '/contractCustomerInfo/page',
        method: 'get',
        params
    });
};
// 客户明细删除
export const contractCustomerDelete = (data) => {
    return request({
        url: apiCrmHost + '/contractCustomerInfo/remove',
        method: 'post',
        data
    });
};
// 客户明细保存
export const contractCustomerSave = (data) => {
    return request({
        url: apiCrmHost + '/contractCustomerInfo/save',
        method: 'post',
        data
    });
};
// 客户明细导入数据校验
export const contractCustomerImport = (data, params) => {
    return request({
        url: apiCrmHost + '/contractCustomerInfo/excel' + params,
        method: 'post',
        data
    });
};
// 客户明细覆盖导入
export const contractCustomerImportSave = (data) => {
    return request({
        url: apiCrmHost + '/contractCustomerInfo/excel/save',
        method: 'post',
        data
    });
};
// 预签明细查询
export const preContractList = (params) => {
    return request({
        url: apiCrmHost + '/preContractInfo/page',
        method: 'get',
        params
    });
};
// 预签明细删除
export const preContractDelete = (data) => {
    return request({
        url: apiCrmHost + '/preContractInfo/remove',
        method: 'post',
        data
    });
};
// 预签明细保存
export const preContractSave = (data) => {
    return request({
        url: apiCrmHost + '/preContractInfo/save',
        method: 'post',
        data
    });
};
// 分公司明细查询
export const companyList = (params) => {
    return request({
        url: apiCrmHost + '/contract/company/info/page',
        method: 'get',
        params
    });
};
// 分公司明细删除
export const companyDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/info/remove',
        method: 'DELETE',
        data
    });
};
// 分公司明细保存
export const companySave = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/info/add',
        method: 'post',
        data
    });
};
// 油价信息查询
export const oilInfoList = (params) => {
    return request({
        url: apiCrmHost + '/contract/oil/info/page',
        method: 'get',
        params
    });
};
// 油价信息删除
export const oilInfoDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/oil/info/remove',
        method: 'DELETE',
        data
    });
};
// 油价信息保存
export const oilInfoSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/oil/info/add/batch',
        method: 'post',
        data
    });
};
// 仓库信息查询
export const warehouseInfoList = (params) => {
    return request({
        url: apiCrmHost + '/contract/price/header/wh/page',
        method: 'get',
        params
    });
};
// 仓库信息删除
export const warehouseInfoDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/price/header/wh/remove',
        method: 'DELETE',
        data
    });
};
// 仓库信息保存
export const warehouseInfoSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/price/header/wh/add',
        method: 'post',
        data
    });
};
// 业务税率查询
export const rateInfoList = (params) => {
    return request({
        url: apiCrmHost + '/contract/rate/info/page',
        method: 'get',
        params
    });
};
// 业务税率信息删除
export const rateInfoDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/rate/info/remove',
        method: 'DELETE',
        data
    });
};
// 业务税率信息保存
export const rateInfoSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/rate/info/add/batch',
        method: 'post',
        data
    });
};
// 变更列表查询
export const contractChangeList = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/page',
        method: 'post',
        data
    });
};
// 变更列表详情
export const contractChangeDetailApi = (params) => {
    return request({
        url: apiCrmHost + '/contract/change/getById',
        method: 'get',
        params
    });
};
// 变更列表删除
export const contractChangeDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/change/remove',
        method: 'DELETE',
        data
    });
};
// 其他变更客户明细查询
export const contractOtherChangeCustomerList = (params) => {
    return request({
        url: apiCrmHost + '/contractCustomerChange/page',
        method: 'get',
        params
    });
};
// 其他变更客户明细删除
export const contractOtherChangeCustomerDelete = (data) => {
    return request({
        url: apiCrmHost + '/contractCustomerChange/remove',
        method: 'post',
        data
    });
};
// 其他变更客户明细保存
export const contractOtherChangeCustomerSave = (data) => {
    return request({
        url: apiCrmHost + '/contractCustomerChange/save',
        method: 'post',
        data
    });
};
// 其他变更客户明细导入
export const contractCustomerChangeImport = (data, params) => {
    return request({
        url: apiCrmHost + '/contractCustomerChange/excel' + params,
        method: 'post',
        data
    });
};
// 其他变更客户明细导入
export const contractCustomerChangeImportSave = (data) => {
    return request({
        url: apiCrmHost + '/contractCustomerChange/excel/save',
        method: 'post',
        data
    });
};
// 其他变更预签明细查询
export const otherChangePreContractList = (params) => {
    return request({
        url: apiCrmHost + '/preContractChange/page',
        method: 'get',
        params
    });
};
// 其他变更预签明细删除
export const otherChangePreContractDelete = (data) => {
    return request({
        url: apiCrmHost + '/preContractChange/remove',
        method: 'post',
        data
    });
};
// 其他变更预签明细保存
export const otherChangePreContractSave = (data) => {
    return request({
        url: apiCrmHost + '/preContractChange/save',
        method: 'post',
        data
    });
};
// 其他变更分公司明细查询
export const otherChangeCompanyList = (params) => {
    return request({
        url: apiCrmHost + '/contract/company/change/page',
        method: 'get',
        params
    });
};
// 其他变更分公司明细删除
export const otherChangeCompanyDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/change/remove',
        method: 'DELETE',
        data
    });
};
// 其他变更分公司明细保存-单条
export const otherChangeCompanySave = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/change/add',
        method: 'post',
        data
    });
};
// 分公司子页签-获取分公司的服务平台
export const getSiteInfoPage = (params) => {
    return request({
        url: apiCrmHost + '/contract/site/info/page',
        method: 'get',
        params
    });
};
// 分公司子页签-保存分公司的服务平台
export const siteInfoSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/site/single/save',
        method: 'post',
        data
    });
};
// 分公司子页签-删除分公司的服务平台
export const siteInfoRemove = (data) => {
    return request({
        url: apiCrmHost + '/contract/site/info/remove',
        method: 'DELETE',
        data
    });
};
// 其他变更分公司子页签-获取分公司的服务平台
export const getSiteChangePage = (params) => {
    return request({
        url: apiCrmHost + '/contract/site/change/page',
        method: 'get',
        params
    });
};
// 其他变更分公司子页签-保存分公司的服务平台
export const siteChangeSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/site/change/single/save',
        method: 'post',
        data
    });
};
// 其他变更分公司子页签-删除分公司的服务平台
export const siteChangeRemove = (data) => {
    return request({
        url: apiCrmHost + '/contract/site/change/remove',
        method: 'DELETE',
        data
    });
};
// 其他变更油价信息查询
export const otherChangeOilInfoList = (params) => {
    return request({
        url: apiCrmHost + '/contract/oil/change/page',
        method: 'get',
        params
    });
};
// 其他变更油价信息删除
export const otherChangeOilInfoDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/oil/change/remove',
        method: 'DELETE',
        data
    });
};
// 其他变更油价信息保存
export const otherChangeOilInfoSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/oil/change/add/batch',
        method: 'post',
        data
    });
};
// 子页签-流程信息-分页
export const contractWorkflowList = (params) => {
    return request({
        url: apiCrmHost + '/contractWorkflowInfo',
        method: 'get',
        params
    });
};
// 批量同步分公司
export const companyUpdateBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/updateBatch',
        method: 'post',
        data
    });
};
// 分公司明细保存-批量
export const companyAddBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/info/addBatch',
        method: 'post',
        data
    });
};
// 其他变更分公司明细保存-批量
export const otherChangeCompanyAddBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/change/addBatch',
        method: 'post',
        data
    });
};
// 流程信息删除
export const contractWorkflowInfoRemove = (data) => {
    return request({
        url: apiCrmHost + '/contract/workflow/info/remove',
        method: 'post',
        data
    });
};

// 分公司明细保存-批量-特权新增商机
export const privilegeAddBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/info/privilegeAddBatch',
        method: 'post',
        data
    });
};
// 分公司明细删除-特权删除
export const privilegeRemove = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/info/privilegeRemove',
        method: 'DELETE',
        data
    });
};
// 分公司子页签-批量添加合同下的服务平台
export const siteUpdateBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/site/save',
        method: 'post',
        data
    });
};
// 分公司子页签-批量添加合同下的服务平台
export const changeSiteUpdateBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/site/change/save/remove',
        method: 'post',
        data
    });
};
// 分公司子页签-特权添加服务平台
export const siteInfoSavePrivilege = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/site/single/save/privilege',
        method: 'post',
        data
    });
};
// 分公司子页签-特权删除服务平台
export const siteInfoRemovePrivilege = (data) => {
    return request({
        url: apiCrmHost + '/contract/site/info/privilegeRemove',
        method: 'DELETE',
        data
    });
};
// 流行信息子页签-特权删除
export const contractFlowAbandom = (data) => {
    return request({
        url: apiCrmHost + '/contract/workflow/info/abandon',
        method: 'POST',
        data
    });
};
// 分公司页签-批量查询已选择服务平台
export const getSiteInfoCompanyAll = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/site/all',
        method: 'POST',
        data
    });
};
// 分公司页签-批量查询已选择服务平台--变更单
export const getChangeSiteInfoCompanyAll = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/change/site/list',
        method: 'POST',
        data
    });
};

// 分公司页签-批量查询已选择分公司
export const getCompanyInfoAll = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/exist/db',
        method: 'POST',
        data
    });
};
// 分公司页签-批量查询已选择分公司-变更单
export const getChangeCompanyInfoAll = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/change/exist/db',
        method: 'POST',
        data
    });
};
// 分公司页签-批量维护服务平台
export const getSiteBatch = (data) => {
    return request({
        url: apiCrmHost + '/contract/company/count',
        method: 'POST',
        data
    });
};
// 附件页签-JDSK生成主合同文件
export const createJDSKFile = (data) => {
    return request({
        url: apiCrmHost + '/contractEnclosureInfo/create/JDSK',
        method: 'POST',
        data
    });
};
