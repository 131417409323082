import request from '@/utils/http';
// const apiCrmHost = 'api-lcrm';
// const apiDcHost = 'api-dc';
const apiFlowHost = 'api-iflow';
const apiSrmFlowHost = 'api-srm';
// 查询mip流程审批人-非法务
export const getProcessTableInfoAndManualNodesApi = (params) => {
    return request({
        url: apiFlowHost + `/getProcessTableInfoAndManualNodes`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 查询审批记录-非法务
export const getAuditeNoteAllApi = (params) => {
    return request({
        url: apiFlowHost + `/public/getAuditeNoteAll`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 查询审批记录-法务
export const getAuditeNoteApi = (params) => {
    return request({
        url: apiSrmFlowHost + `/public/fw/getAuditeNote`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 查询当前操作人-（法务、非法务公用）
export const getProcessInfoApi = (params) => {
    return request({
        url: apiFlowHost + `/public/getProcessInfo`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 查询mip流程审批人-法务
export const fwGetProcessTableInfoAndManualNodesApi = (params) => {
    return request({
        url: apiSrmFlowHost + `/public/fw/getProcessTableInfoAndManualNodes`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 通过fdid,mip账号查询mip流程是否有查看、编辑权限
export const getAuthorByProcessIdApi = (params) => {
    return request({
        url: apiFlowHost + `/getAuthorByProcessId`,
        method: 'post',
        data: params
    }).then(res => res);
};
